<template>
  <el-dialog
    :visible.sync="visible"
    title="创建收款"
    :close-on-click-modal="false"
    class="f-dialog"
    :before-close="close"
    width="500px"
    append-to-body
  >
    <div>
      <div class="pb24 bdb x3 aic">
        <div>
          <p class="fs16 bold mb8">保证金</p>
          <p class="fs13 color9">竞拍保证金</p>
        </div>
        <img style="width:30px;" src="../../../../../assets/shou.png" />
      </div>
      <div class="ph24 bdb x3 aic">
        <p>应收保证金</p>
        <p>{{ option.appointmentAmount | toThousands }}</p>
      </div>
      <div class="ph24 bdb">
        <div class="x3 aic mb8">
          <p>诚意金转入</p>
          <p>¥{{ option.freezeAmount | toThousands }}</p>
        </div>
        <div class="x3 aic">
          <p>本次应收款</p>
          <p class="fs20 red">¥{{ option.unpayAmount | toThousands }}</p>
        </div>
      </div>
      <div class="pt24">
        <p class="mb16">确认后不可修改，你确定要执行收款确认吗？</p>
        <el-input type="textarea" :rows="2" placeholder="备注" v-model="reamk">
        </el-input>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">
        确认收款
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { managePay } from "@/api/marketActivity";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      reamk: ""
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.reamk = "";
      }
    }
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    async submit() {
      let id = this.option.id;
      let query = {
        reamk: this.reamk
      };
      const res = await managePay(id, query);
      if (res) {
        this.$showSuccess("操作成功");
        this.close();
        this.$emit("refresh");
      }
    }
  }
};
</script>
