<template>
  <div class="marketing-tab y">
    <div class="activity-box x aic">
      活动：
      <el-select
        v-model="checkActivity"
        placeholder="请选择"
        class="activity-select"
        @change="getActivity"
      >
        <el-option
          v-for="(item, i) in activityList"
          :key="i"
          :label="item.activityName"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="tabs">
      <el-tabs class="f-tabs" v-model="activeName">
        <el-tab-pane
          v-for="(item, index) in tabs"
          :label="item"
          :name="`${index}`"
          :key="index"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="wrap">
      <marketing
        v-show="activeName == 0"
        class="mt20"
        :checkActivity="checkActivity"
      />
      <bidding
        v-show="activeName == 1"
        class="mt20"
        :activity="currentActivity"
      />
    </div>
  </div>
</template>

<script>
import { getActivityList } from "@/api/marketActivity";
import marketing from "@/components/marketing";
import bidding from "./bidding";
export default {
  components: {
    marketing,
    bidding
  },
  data() {
    return {
      tabs: ["营销礼包", "竞拍专场"],
      activeName: "1",
      checkActivity: "",
      activityList: [],
      currentActivity: {}
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  created() {
    this.getActivityList();
  },
  methods: {
    async getActivityList() {
      this.activityList = (await getActivityList(this.estateId)) || [];
      if (this.activityList.length) {
        this.checkActivity = this.activityList[0].id;
        this.currentActivity = this.activityList[0];
      }
    },
    getActivity(e) {
      this.activityList.forEach(el => {
        if (el.id === e) {
          this.currentActivity = el;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.marketing-tab {
  min-height: 100%;
  & > div {
    background: #fff;
    border-radius: 5px;
  }
  .activity-box {
    height: 60px;
    padding: 0 20px;
    margin-bottom: 10px;
    .activity-select {
      width: 300px;
      ::v-deep {
        .el-input {
          .el-input__inner {
            border: none;
            font-size: 16px;
            color: #3470fc;
          }
        }
      }
    }
  }

  .tabs {
    padding: 0 20px;
    background: #fff;
    ::v-deep {
      .el-tabs__header {
        background: #fff;
        margin: 0;
      }
    }
  }
  .wrap {
    flex: 1;
    overflow-y: auto;
  }
}
::v-deep {
  .marketing-container {
    padding: 0 20px;
  }
}
</style>
