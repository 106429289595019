var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "f-dialog editRemark",
    attrs: {
      "visible": _vm.visible,
      "title": _vm.title,
      "close-on-click-modal": false,
      "before-close": _vm.close,
      "width": "640px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', [_c('p', {
    staticClass: "bold fs16 color3 h60 bdb x aic pw20 mb24"
  }, [_c('span', {
    staticClass: "color9"
  }, [_vm._v("标的物：")]), _vm._v(" " + _vm._s(_vm.option.name) + " ")]), _c('div', {
    staticClass: "x3 aic pw20 mb16"
  }, [_c('div', {
    staticClass: "color9"
  }, [_c('p', {
    staticClass: "fs16"
  }, [_vm._v("相关图片")]), _c('p', [_vm._v("选填，支持jpg、png、gif格式，建议大小1M以内，最多可上传9张")])]), _c('f-upload', {
    ref: "picUploader",
    attrs: {
      "type": "4",
      "title": "批量上传图片"
    },
    on: {
      "on-success": _vm.imgUploadSuccess
    }
  })], 1), _c('div', {
    staticClass: "img-box pw20 mb24"
  }, _vm._l(_vm.attachs, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "img-cell"
    }, [_c('img', {
      staticClass: "mb8",
      attrs: {
        "src": item.fileUrl
      }
    }), _c('p', {
      staticClass: "pointer x1 aic color9 fs13",
      on: {
        "click": function click($event) {
          return _vm.delImg(index);
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-delete"
    }), _vm._v(" 删除 ")])]);
  }), 0), _c('quill-editor', {
    ref: "text",
    staticClass: "myQuillEditor pw20 mb24",
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.remark,
      callback: function callback($$v) {
        _vm.remark = $$v;
      },
      expression: "remark"
    }
  })], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保 存")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }