var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.visible,
      "title": _vm.title,
      "close-on-click-modal": false,
      "before-close": _vm.close,
      "width": "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "form",
    staticClass: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "120px"
    }
  }, [_vm.submitName == '提 交' ? _c('el-form-item', {
    attrs: {
      "label": "类型：",
      "prop": "targetType",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.targetType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "targetType", $$v);
      },
      expression: "formData.targetType"
    }
  }, [_vm.activity.activityType == 0 ? _c('el-radio-button', {
    attrs: {
      "label": "1"
    }
  }, [_vm._v(" 房源 ")]) : _vm._e(), _vm.activity.activityType == 1 ? _c('el-radio-button', {
    attrs: {
      "label": "2"
    }
  }, [_vm._v(" 车位 ")]) : _vm._e(), _c('el-radio-button', {
    attrs: {
      "label": "3"
    }
  }, [_vm._v("其他")])], 1)], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "标的物：",
      "prop": "name",
      "rules": [_vm.$formRules.required()]
    }
  }, [_vm.formData.targetType == 1 || _vm.formData.targetType == 2 ? _c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "filterable": "",
      "remote": "",
      "reserve-keyword": "",
      "remote-method": _vm.selectBidding,
      "loading": _vm.loading,
      "placeholder": "\u8BF7\u9009\u62E9\u7ADE\u62CD".concat(_vm.formData.targetType == 1 ? '房源' : '车位'),
      "disabled": _vm.submitName !== '提 交'
    },
    on: {
      "change": _vm.getSelect
    },
    model: {
      value: _vm.formData.targetId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "targetId", $$v);
      },
      expression: "formData.targetId"
    }
  }, _vm._l(_vm.selectBiddingList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1) : _c('el-input', {
    attrs: {
      "placeholder": "请输入标的物名称"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "保证金：",
      "prop": "ensureAmount",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('div', [_c('el-input', {
    attrs: {
      "disabled": _vm.submitName === '继续竞拍',
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.ensureAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "ensureAmount", $$v);
      },
      expression: "formData.ensureAmount"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2), _c('p', {
    staticClass: "fs13 color9 lh20 mt8"
  }, [_vm._v(" 当前开盘活动诚意金¥" + _vm._s(_vm._f("toThousands")(_vm.activity.earnestMoney)) + "，保证金建议保持一致； ")])], 1)]), _c('el-form-item', {
    attrs: {
      "label": "起拍价：",
      "prop": "biddingAmount",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('div', [_c('el-input', {
    attrs: {
      "disabled": _vm.submitName === '继续竞拍',
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.biddingAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "biddingAmount", $$v);
      },
      expression: "formData.biddingAmount"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2), _vm.formData.targetType != 3 ? _c('p', {
    staticClass: "fs13 color9 lh20 mt8"
  }, [_vm._v(" 优惠后总价：¥" + _vm._s(_vm._f("toThousands")(_vm.selectItem.discountAfterPrice)) + " ")]) : _vm._e()], 1)]), _c('el-form-item', {
    attrs: {
      "label": "加价幅度：",
      "prop": "biddingStepAmount",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.biddingStepAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "biddingStepAmount", $$v);
      },
      expression: "formData.biddingStepAmount"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "延时周期：",
      "prop": "delay",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('div', [_c('el-radio-group', {
    on: {
      "change": _vm.delayChange
    },
    model: {
      value: _vm.delay,
      callback: function callback($$v) {
        _vm.delay = $$v;
      },
      expression: "delay"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": 1
    }
  }, [_vm._v(" 延时 ")]), _c('el-radio-button', {
    attrs: {
      "label": 0
    }
  }, [_vm._v("不延时")])], 1), _vm.delay ? [_c('el-input', {
    staticClass: "mt8",
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.delay,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "delay", $$v);
      },
      expression: "formData.delay"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("分钟")])], 2), _c('p', {
    staticClass: "fs13 color9 lh20 mt8"
  }, [_vm._v(" 推荐5分钟延时周期，即拍卖结束前五分钟内出价，拍卖结束时间会在出价时间的基础上延长五分钟，五分钟内的每次出价都会触发延时 ")])] : _vm._e()], 2)]), _c('el-form-item', {
    attrs: {
      "label": "开始时间：",
      "prop": "startDate",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('div', [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "datetime",
      "placeholder": "竞拍开始时间",
      "format": "yyyy-MM-dd HH:mm:ss",
      "value-format": "yyyy-MM-dd HH:mm:ss"
    },
    model: {
      value: _vm.formData.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "startDate", $$v);
      },
      expression: "formData.startDate"
    }
  }), _c('p', {
    staticClass: "fs13 color9 lh20 mt8"
  }, [_vm._v(" 开始时间必须在开盘活动正式开始后； ")])], 1)]), _c('el-form-item', {
    attrs: {
      "label": "结束时间：",
      "prop": "endDate",
      "rules": [_vm.$formRules.required(), {
        validator: _vm.checkTime,
        trigger: 'blur'
      }]
    }
  }, [_c('div', [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "datetime",
      "placeholder": "竞拍结束时间",
      "format": "yyyy-MM-dd HH:mm:ss",
      "value-format": "yyyy-MM-dd HH:mm:ss"
    },
    model: {
      value: _vm.formData.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "endDate", $$v);
      },
      expression: "formData.endDate"
    }
  }), _c('p', {
    staticClass: "fs13 color9 lh20 mt8"
  }, [_vm._v(" 结束时间必须在开盘活动结束前，且竞拍周期至少30分钟以上； ")])], 1)]), _c('el-form-item', {
    attrs: {
      "label": "缩略图：",
      "prop": "corverImage",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('div', {
    staticClass: "auth-content-wrap"
  }, [_c('p', {
    staticClass: "color9 lh40"
  }, [_vm._v("建议尺寸400px *300px，大小1M以内")]), _c('f-upload', {
    attrs: {
      "required": "",
      "type": 2,
      "default-file-list": _vm.formData.corverImage
    },
    on: {
      "on-success": _vm.uploadSuccess
    }
  })], 1)])], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.submitName) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }