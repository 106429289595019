<template>
  <el-dialog
    :visible.sync="visible"
    title="竞拍报名"
    center
    :close-on-click-modal="false"
    class="f-dialog"
    :before-close="close"
    width="70%"
  >
    <div>
      <el-table class="body" :data="tableList" :row-class-name="rowStyle">
        <el-table-column label="竞买人" width="240px">
          <div slot-scope="scope">
            <p>{{ scope.row.customerName }}</p>
            <p class="color9">{{ scope.row.customerPhone }}</p>
            <p class="color9">{{ scope.row.customerIdcard }}</p>
          </div>
        </el-table-column>
        <el-table-column label="保证金" width="180px">
          <div slot-scope="scope" class="x aic">
            <div class="mr8">
              <p>¥{{ scope.row.appointmentAmount }}</p>
              <p
                v-for="(item, index) in scope.row.logItems"
                :key="index"
                :class="{
                  red: item.logType == 0,
                  green: item.logType != 0 && item.logType != 20
                }"
              >
                {{ item.logDesc }}
              </p>
            </div>
            <el-popover
              width="320"
              trigger="click"
              v-if="
                (scope.row.refundItems && scope.row.refundItems.length) ||
                  (scope.row.payItems && scope.row.payItems.length)
              "
            >
              <div class="popover">
                <div
                  class="pb24 bdb"
                  v-if="scope.row.refundItems && scope.row.refundItems.length"
                >
                  <p class="fs16 bold">退款记录</p>
                  <div
                    class="mt8"
                    v-for="(item, index) in scope.row.refundItems"
                    :key="index"
                  >
                    <div v-if="item.payType == 1">
                      <p class="x aic green mb8">
                        <i class="iconfont mr8 colo3 fs20">&#xe63a;</i>
                        微信支付
                      </p>
                      <p>
                        <span class="color9 mr8">退款状态</span>
                        <span class="red">已退款¥{{ item.amount }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">退款时间</span>
                        <span>{{ item.date }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">交易单号</span>
                        <span>{{ item.tradeNo }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">退款发起人</span>
                        <span>{{ item.userName }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">退款备注</span>
                        <span>{{ item.remark }}</span>
                      </p>
                    </div>
                    <div v-if="item.payType == 10">
                      <p class="x aic blue mb8">
                        <i class="iconfont mr8 colo3 fs20">&#xe63e;</i>
                        线下支付
                      </p>
                      <p>
                        <span class="color9 mr8">退款状态</span>
                        <span class="red">已退款¥{{ item.amount }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">退款确认人</span>
                        <span>{{ item.userName }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">退款时间</span>
                        <span>{{ item.date }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">退款备注</span>
                        <span>{{ item.remark }}</span>
                      </p>
                    </div>
                    <div v-if="item.payType == 15">
                      <p class="x aic red mb8">
                        <img
                          class="mr8"
                          src="../../../../../assets/icon_tui.png"
                          style="width:20px;"
                        />
                        诚意金退还
                      </p>
                      <p>
                        <span class="color9 mr8">退款状态</span>
                        <span class="red">已退款¥{{ item.amount }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">退款时间</span>
                        <span>{{ item.date }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="pb24"
                  v-if="scope.row.payItems && scope.row.payItems.length"
                >
                  <p class="fs16 bold">收款记录</p>
                  <div
                    class="mt8"
                    v-for="(item, index) in scope.row.payItems"
                    :key="index"
                  >
                    <div v-if="item.payType == 1">
                      <p class="x aic green mb8">
                        <i class="iconfont mr8 colo3 fs20">&#xe63a;</i>
                        微信支付
                      </p>
                      <p>
                        <span class="color9 mr8">收款状态</span>
                        <span class="red">已收款¥{{ item.amount }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">收款商户</span>
                        <span>{{ item.mchName }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">支付时间</span>
                        <span>{{ item.date }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">交易单号</span>
                        <span>{{ item.tradeNo }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">商户单号</span>
                        <span>{{ item.orderNo }}</span>
                      </p>
                    </div>
                    <div v-if="item.payType == 10">
                      <p class="x aic blue mb8">
                        <i class="iconfont mr8 colo3 fs20">&#xe63e;</i>
                        线下支付
                      </p>
                      <p>
                        <span class="color9 mr8">收款状态</span>
                        <span class="red">已收款¥{{ item.amount }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">确认收款人</span>
                        <span>{{ item.userName }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">确认时间</span>
                        <span>{{ item.date }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">收款备注</span>
                        <span>{{ item.remark }}</span>
                      </p>
                    </div>
                    <div v-if="item.payType == 15">
                      <p class="x aic red mb8">
                        <img
                          class="mr8"
                          src="../../../../../assets/icon_tui.png"
                          style="width:20px;"
                        />
                        诚意金转入
                      </p>
                      <p>
                        <span class="color9 mr8">收款状态</span>
                        <span class="red">已收款¥{{ item.amount }}</span>
                      </p>
                      <p>
                        <span class="color9 mr8">转入时间</span>
                        <span>{{ item.date }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <i class="el-icon-info pointer" slot="reference"></i>
            </el-popover>
          </div>
        </el-table-column>
        <el-table-column label="竞买号">
          <div slot-scope="scope">
            <p>{{ scope.row.appointmentNo }}</p>
          </div>
        </el-table-column>
        <el-table-column label="报名时间" width="180px">
          <div slot-scope="scope">
            <p>{{ scope.row.inDate }}</p>
          </div>
        </el-table-column>
        <el-table-column label="状态">
          <div slot-scope="scope">
            <p>{{ scope.row.status == 20 ? "已退款" : "正常" }}</p>
          </div>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="managePay(scope.row)"
              v-if="scope.row.status == 1"
            >
              收款
            </el-button>
            <el-button
              type="text"
              @click="manageRefund(scope.row)"
              v-if="scope.row.status == 5 && scope.row.appointmentAmount"
            >
              退款
            </el-button>
            <el-button
              type="text"
              @click="manageRemove(scope.row)"
              v-if="scope.row.status == 1 || scope.row.status == 20"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="query.pageNum"
        :page-sizes="pageSizes"
        :page-size="query.pageSize"
        layout="sizes, prev, pager, next,jumper"
        :total="total"
      >
      </el-pagination>
      <payForm
        :visible.sync="toggleDialogPay"
        :option="currentItem"
        @refresh="getData"
      />
      <refundForm
        :visible.sync="toggleDialogRefund"
        :option="currentItem"
        @refresh="getData"
      />
    </div>
  </el-dialog>
</template>

<script>
import { appointmentList, manageRemove } from "@/api/marketActivity";
import payForm from "./payForm";
import refundForm from "./refundForm";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    payForm,
    refundForm
  },
  data() {
    return {
      tableList: [],
      query: {
        pageNum: 1,
        pageSize: 10
      },
      pageSizes: [10, 20, 30, 50],
      total: 0,
      toggleDialogPay: false,
      toggleDialogRefund: false,
      currentItem: {}
    };
  },
  computed: {
    id() {
      return this.option.id || "";
    }
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    }
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    init() {
      this.getData();
    },
    rowStyle({ row }) {
      if (row.status == 20) {
        return "disColor";
      }
      return "";
    },
    async getData() {
      const res = await appointmentList(this.id, this.query);
      if (res) {
        const { list, total } = res;
        this.tableList = list || [];
        this.total = total;
      }
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.query.pageNum = val;
      this.getData();
    },
    managePay(item) {
      this.currentItem = item;
      this.toggleDialogPay = true;
    },
    manageRefund(item) {
      this.currentItem = item;
      this.toggleDialogRefund = true;
    },
    manageRemove(item) {
      this.$showConfirm(async () => {
        const res = await manageRemove(item.id);
        if (res) {
          this.$showSuccess("操作成功");
          this.getData();
        }
      }, "是否确认删除该数据?");
    }
  }
};
</script>

<style lang="scss" scoped>
.popover {
  max-height: 60vh;
  overflow: auto;
}
</style>
