var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.visible,
      "title": "竞拍记录",
      "center": "",
      "close-on-click-modal": false,
      "before-close": _vm.close,
      "width": "800px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', [_vm.title ? _c('div', {
    staticClass: "pd20 mb24",
    staticStyle: {
      "background": "#f2f2f2"
    }
  }, [_c('p', {
    staticClass: "mb12"
  }, [_c('span', {
    staticClass: "fs16"
  }, [_vm._v("竞拍成交单")]), _c('span', {
    staticClass: "color9"
  }, [_vm._v(" 复制订单号，请在“认购订单”页面查询订单详情 ")])]), _c('div', {
    staticClass: "x"
  }, [_c('div', {
    staticClass: "flex1"
  }, [_c('p', {
    staticClass: "mb8"
  }, [_vm._v(" " + _vm._s(_vm.title.orderNo) + " "), _c('svg-icon', {
    staticClass: "copy-icon pointer",
    attrs: {
      "icon-class": "copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.title.orderNo);
      }
    }
  })], 1), _c('p', {
    staticClass: "red"
  }, [_vm._v("竞买价¥" + _vm._s(_vm._f("toThousands")(_vm.title.price)))])]), _c('div', {
    staticClass: "flex1"
  }, [_c('p', {
    staticClass: "mb8"
  }, [_vm._v(" " + _vm._s(_vm.title.bidNo) + " ")]), _c('p', {
    staticClass: "color9"
  }, [_vm._v(" " + _vm._s(_vm.title.bidderName) + "，" + _vm._s(_vm.title.bidderCellphone) + " ")])]), _c('div', {
    staticClass: "flex1"
  }, [_c('p', {
    staticClass: "mb8"
  }, [_vm._v(" " + _vm._s(_vm.title.bidTargetFullName || "暂无数据") + " ")]), _c('p', {
    staticClass: "color9"
  }, [_vm._v(" " + _vm._s(_vm.title.targetInfoDesc) + " ")])])])]) : _vm._e(), _c('el-table', {
    staticClass: "body",
    attrs: {
      "data": _vm.tableList,
      "row-class-name": _vm.rowStyle
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {}, [_c('p', [_vm._v(_vm._s(scope.row.tradeStateDesc))])]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "竞买号"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {}, [_c('p', [_vm._v(_vm._s(scope.row.bidNo))])]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "出价"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {}, [_c('p', [_vm._v(_vm._s(_vm._f("toThousands")(scope.row.price)))])]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "时间"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {}, [_c('p', [_vm._v(_vm._s(scope.row.createTime))])]);
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.query.pageIndex,
      "page-sizes": _vm.pageSizes,
      "page-size": _vm.query.pageSize,
      "layout": "sizes, prev, pager, next,jumper",
      "total": _vm.total
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange,
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }