<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    class="f-dialog"
    :before-close="close"
    width="500px"
  >
    <el-form :model="formData" class="form" ref="form" label-width="120px">
      <el-form-item
        label="类型："
        prop="targetType"
        :rules="[$formRules.required()]"
        v-if="submitName == '提 交'"
      >
        <el-radio-group v-model="formData.targetType">
          <el-radio-button label="1" v-if="activity.activityType == 0">
            房源
          </el-radio-button>
          <el-radio-button label="2" v-if="activity.activityType == 1">
            车位
          </el-radio-button>
          <el-radio-button label="3">其他</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="标的物："
        prop="name"
        :rules="[$formRules.required()]"
      >
        <el-select
          v-if="formData.targetType == 1 || formData.targetType == 2"
          v-model="formData.targetId"
          filterable
          remote
          reserve-keyword
          :remote-method="selectBidding"
          :loading="loading"
          :placeholder="
            `请选择竞拍${formData.targetType == 1 ? '房源' : '车位'}`
          "
          style="width: 100%;"
          @change="getSelect"
          :disabled="submitName !== '提 交'"
        >
          <el-option
            v-for="(item, index) in selectBiddingList"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          v-else
          v-model="formData.name"
          placeholder="请输入标的物名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="保证金："
        prop="ensureAmount"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <div>
          <el-input
            :disabled="submitName === '继续竞拍'"
            v-model="formData.ensureAmount"
            placeholder="请输入"
          >
            <template slot="append">元</template>
          </el-input>
          <p class="fs13 color9 lh20 mt8">
            当前开盘活动诚意金¥{{
              activity.earnestMoney | toThousands
            }}，保证金建议保持一致；
          </p>
        </div>
      </el-form-item>
      <el-form-item
        label="起拍价："
        prop="biddingAmount"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <div>
          <el-input
            :disabled="submitName === '继续竞拍'"
            v-model="formData.biddingAmount"
            placeholder="请输入"
          >
            <template slot="append">元</template>
          </el-input>
          <p class="fs13 color9 lh20 mt8" v-if="formData.targetType != 3">
            优惠后总价：¥{{ selectItem.discountAfterPrice | toThousands }}
          </p>
        </div>
      </el-form-item>
      <el-form-item
        label="加价幅度："
        prop="biddingStepAmount"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <el-input v-model="formData.biddingStepAmount" placeholder="请输入">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="延时周期："
        prop="delay"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <div>
          <el-radio-group v-model="delay" @change="delayChange">
            <el-radio-button :label="1">
              延时
            </el-radio-button>
            <el-radio-button :label="0">不延时</el-radio-button>
          </el-radio-group>
          <template v-if="delay">
            <el-input class="mt8" v-model="formData.delay" placeholder="请输入">
              <template slot="append">分钟</template>
            </el-input>
            <p class="fs13 color9 lh20 mt8">
              推荐5分钟延时周期，即拍卖结束前五分钟内出价，拍卖结束时间会在出价时间的基础上延长五分钟，五分钟内的每次出价都会触发延时
            </p>
          </template>
        </div>
      </el-form-item>
      <el-form-item
        label="开始时间："
        prop="startDate"
        :rules="[$formRules.required()]"
      >
        <div>
          <el-date-picker
            style="width:100%"
            v-model="formData.startDate"
            type="datetime"
            placeholder="竞拍开始时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
          <p class="fs13 color9 lh20 mt8">
            开始时间必须在开盘活动正式开始后；
          </p>
        </div>
      </el-form-item>
      <el-form-item
        label="结束时间："
        prop="endDate"
        :rules="[
          $formRules.required(),
          { validator: checkTime, trigger: 'blur' }
        ]"
      >
        <div>
          <el-date-picker
            style="width:100%"
            v-model="formData.endDate"
            type="datetime"
            placeholder="竞拍结束时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
          <p class="fs13 color9 lh20 mt8">
            结束时间必须在开盘活动结束前，且竞拍周期至少30分钟以上；
          </p>
        </div>
      </el-form-item>
      <el-form-item
        label="缩略图："
        prop="corverImage"
        :rules="[$formRules.required()]"
      >
        <div class="auth-content-wrap">
          <p class="color9 lh40">建议尺寸400px *300px，大小1M以内</p>
          <f-upload
            required
            :type="2"
            :default-file-list="formData.corverImage"
            @on-success="uploadSuccess"
          ></f-upload>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">
        {{ submitName }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

import { link } from "@/utils/index";

import {
  selectBidding,
  editBidding,
  proceedBidding
} from "@/api/marketActivity";

import FUpload from "@/components/upload";

const formData = {
  biddingAmount: null,
  biddingStepAmount: null,
  corverImage: "",
  delay: 0,
  endDate: "",
  ensureAmount: null,
  name: "",
  openQuotationActivityId: null,
  startDate: "",
  targetId: null,
  targetType: ""
};
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default() {
        return {};
      }
    },
    activity: {
      type: Object,
      required: true
    }
  },
  components: {
    FUpload
  },
  data() {
    return {
      formData: {},
      list: [],
      loading: false,
      selectBiddingList: [],
      delay: 0,
      selectItem: {}
    };
  },
  computed: {
    id() {
      return this.option.id || "";
    },
    title() {
      let title = "创建竞拍";
      if (this.id) {
        title = "编辑竞拍";
      }
      return title;
    },
    submitName() {
      let name = "提 交";
      let currentStatus = this.option.currentStatus || "";
      if (currentStatus == 5 || currentStatus == 7) {
        name = "重新挂拍";
      }
      if (currentStatus == 6) {
        name = "继续竞拍";
      }
      return name;
    }
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    }
  },
  methods: {
    init() {
      const { id, option, activity } = this;
      if (id) {
        this.formData = this.$deepClone(option);
        this.selectBidding(option.targetId);
        this.delay = option.delay && 1;
      } else {
        this.formData = this.$deepClone(formData);
        this.formData.openQuotationActivityId = activity.id;
        this.selectBiddingList = [];
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    delayChange(e) {
      if (e) {
        this.formData.delay = "";
      } else {
        this.formData.delay = 0;
      }
    },
    getSelect(e) {
      let item = this.selectBiddingList.find(el => el.id == e);
      this.selectItem = item;
      this.formData.name = item.name;
    },
    async selectBidding(keyword) {
      if (!keyword) {
        this.selectBiddingList = [];
        return;
      }
      this.loading = true;
      let id = this.activity.id;
      let type = this.formData.targetType;
      let query = {
        keyword: keyword,
        type
      };
      if (this.id) {
        query.id = this.id;
      }
      const res = await selectBidding(id, query);
      this.loading = false;
      if (res) {
        let list = [];
        res.forEach(e => {
          const {
            bulidingName,
            discountAfterPrice,
            floorName,
            id,
            parkingHouseNo,
            unitName
          } = e;
          let name = link([bulidingName, unitName, floorName, parkingHouseNo]);
          list.push({
            id,
            name,
            discountAfterPrice
          });
        });
        this.selectBiddingList = list;
      }
    },
    checkTime(rule, value, callback) {
      let time = this.formData.formalStartTime;
      if (moment(time).isBefore(value)) {
        callback();
      } else {
        callback(new Error("时间须大于开始时间"));
      }
    },
    close() {
      this.$emit("update:visible", false);
    },
    submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }
        if (this.delay && this.formData.delay <= 0) {
          this.$showError("延迟时间须大于0");
          return;
        }
        let res = false;
        if (this.submitName == "提 交") {
          res = await editBidding(this.formData);
        } else {
          let proceedType = null;
          if (this.submitName == "继续竞拍") {
            proceedType = 1;
          }
          if (this.submitName == "重新挂拍") {
            proceedType = 2;
          }
          let query = this.$deepClone(this.formData);
          query.proceedType = proceedType;
          res = await proceedBidding(query);
        }
        if (res) {
          this.$showSuccess("操作成功");
          this.close();
          this.$emit("refresh");
        }
      });
    },
    uploadSuccess(e) {
      let item = e[e.length - 1];
      this.formData.corverImage = item.url;
    }
  }
};
</script>
