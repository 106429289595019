var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.visible,
      "title": "创建收款",
      "close-on-click-modal": false,
      "before-close": _vm.close,
      "width": "500px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "pb24 bdb x3 aic"
  }, [_c('div', [_c('p', {
    staticClass: "fs16 bold mb8"
  }, [_vm._v("保证金")]), _c('p', {
    staticClass: "fs13 color9"
  }, [_vm._v("竞拍保证金")])]), _c('img', {
    staticStyle: {
      "width": "30px"
    },
    attrs: {
      "src": require("../../../../../assets/shou.png")
    }
  })]), _c('div', {
    staticClass: "ph24 bdb x3 aic"
  }, [_c('p', [_vm._v("应收保证金")]), _c('p', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.option.appointmentAmount)))])]), _c('div', {
    staticClass: "ph24 bdb"
  }, [_c('div', {
    staticClass: "x3 aic mb8"
  }, [_c('p', [_vm._v("诚意金转入")]), _c('p', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.option.freezeAmount)))])]), _c('div', {
    staticClass: "x3 aic"
  }, [_c('p', [_vm._v("本次应收款")]), _c('p', {
    staticClass: "fs20 red"
  }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.option.unpayAmount)))])])]), _c('div', {
    staticClass: "pt24"
  }, [_c('p', {
    staticClass: "mb16"
  }, [_vm._v("确认后不可修改，你确定要执行收款确认吗？")]), _c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 2,
      "placeholder": "备注"
    },
    model: {
      value: _vm.reamk,
      callback: function callback($$v) {
        _vm.reamk = $$v;
      },
      expression: "reamk"
    }
  })], 1)]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" 确认收款 ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }