<template>
  <el-dialog
    :visible.sync="visible"
    title="竞拍记录"
    center
    :close-on-click-modal="false"
    class="f-dialog"
    :before-close="close"
    width="800px"
  >
    <div>
      <div class="pd20 mb24" style="background: #f2f2f2;" v-if="title">
        <p class="mb12">
          <span class="fs16">竞拍成交单</span>
          <span class="color9">
            复制订单号，请在“认购订单”页面查询订单详情
          </span>
        </p>
        <div class="x">
          <div class="flex1">
            <p class="mb8">
              {{ title.orderNo }}
              <svg-icon
                icon-class="copy"
                class="copy-icon pointer"
                @click="copyText(title.orderNo)"
              ></svg-icon>
            </p>
            <p class="red">竞买价¥{{ title.price | toThousands }}</p>
          </div>
          <div class="flex1">
            <p class="mb8">
              {{ title.bidNo }}
            </p>
            <p class="color9">
              {{ title.bidderName }}，{{ title.bidderCellphone }}
            </p>
          </div>
          <div class="flex1">
            <p class="mb8">
              {{ title.bidTargetFullName || "暂无数据" }}
            </p>
            <p class="color9">
              {{ title.targetInfoDesc }}
            </p>
          </div>
        </div>
      </div>
      <el-table class="body" :data="tableList" :row-class-name="rowStyle">
        <el-table-column label="状态">
          <div slot-scope="scope">
            <p>{{ scope.row.tradeStateDesc }}</p>
          </div>
        </el-table-column>
        <el-table-column label="竞买号">
          <div slot-scope="scope">
            <p>{{ scope.row.bidNo }}</p>
          </div>
        </el-table-column>
        <el-table-column label="出价">
          <div slot-scope="scope">
            <p>{{ scope.row.price | toThousands }}</p>
          </div>
        </el-table-column>
        <el-table-column label="时间">
          <div slot-scope="scope">
            <p>{{ scope.row.createTime }}</p>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="query.pageIndex"
        :page-sizes="pageSizes"
        :page-size="query.pageSize"
        layout="sizes, prev, pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import { recordPageForPC } from "@/api/marketActivity";
import { copyText } from "@/utils";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      tableList: [],
      query: {
        pageIndex: 1,
        pageSize: 10
      },
      pageSizes: [10, 20, 30, 50],
      total: 0,
      title: {}
    };
  },
  computed: {
    id() {
      return this.option.id || "";
    }
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    }
  },
  methods: {
    copyText,
    close() {
      this.$emit("update:visible", false);
    },
    init() {
      this.query.bidTargetId = this.id;
      this.getData();
    },
    rowStyle({ row }) {
      if (row.tradeStateDesc == "成交") {
        return "greenAll";
      }
      if (row.tradeStateDesc == "领先") {
        return "redAll";
      }
      if (row.tradeStateDesc == "出局") {
        return "disColor";
      }
      return "";
    },
    async getData() {
      const res = await recordPageForPC(this.query);
      if (res) {
        const { page, title } = res;
        this.title = title;
        const { list, total } = page;
        this.tableList = list || [];
        this.total = total;
      }
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getData();
    }
  }
};
</script>

<style lang="scss">
.ql-container {
  min-height: 200px;
}
</style>
