var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketing-tab y"
  }, [_c('div', {
    staticClass: "activity-box x aic"
  }, [_vm._v(" 活动： "), _c('el-select', {
    staticClass: "activity-select",
    attrs: {
      "placeholder": "请选择"
    },
    on: {
      "change": _vm.getActivity
    },
    model: {
      value: _vm.checkActivity,
      callback: function callback($$v) {
        _vm.checkActivity = $$v;
      },
      expression: "checkActivity"
    }
  }, _vm._l(_vm.activityList, function (item, i) {
    return _c('el-option', {
      key: i,
      attrs: {
        "label": item.activityName,
        "value": item.id
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "tabs"
  }, [_c('el-tabs', {
    staticClass: "f-tabs",
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabs, function (item, index) {
    return _c('el-tab-pane', {
      key: index,
      attrs: {
        "label": item,
        "name": "".concat(index)
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "wrap"
  }, [_c('marketing', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeName == 0,
      expression: "activeName == 0"
    }],
    staticClass: "mt20",
    attrs: {
      "checkActivity": _vm.checkActivity
    }
  }), _c('bidding', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeName == 1,
      expression: "activeName == 1"
    }],
    staticClass: "mt20",
    attrs: {
      "activity": _vm.currentActivity
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }