var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "item-body"
  }, [_c('div', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("全部竞拍")]), _vm.activity.activityType == 1 ? _c('p', {
    staticClass: "summary"
  }, [_vm._v(" “选车位”类型的开盘活动，仅支持关联当前开盘活动的“车位”、“其他”标的物参加竞拍，挂拍后锁定标的物，撤回后释放；"), _c('br')]) : _c('p', {
    staticClass: "summary"
  }, [_vm._v(" “选房”类型的开盘活动，仅支持关联当前开盘活动的“房源”、“其他”标的物参加竞拍，挂拍后锁定标的物，撤回后释放；"), _c('br')])]), _c('div', [_c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        return _vm.editDialog();
      }
    }
  }, [_vm._v(" 创建竞拍 ")])], 1)]), _c('el-table', {
    staticClass: "body",
    attrs: {
      "border": "",
      "data": _vm.tableList
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "标的物"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {
          staticClass: "x"
        }, [_c('div', {
          staticClass: "cover mr10"
        }, [_vm.currentStatusObj[scope.row.currentStatus] ? _c('div', {
          staticClass: "img-tag",
          style: "background-color: ".concat(_vm.currentStatusObj[scope.row.currentStatus].color)
        }, [_vm._v(" " + _vm._s(_vm.currentStatusObj[scope.row.currentStatus].name) + " ")]) : _vm._e(), _c('el-image', {
          staticClass: "cover",
          attrs: {
            "fit": "cover",
            "src": scope.row.corverImage
          }
        })], 1), _c('div', {
          staticClass: "y3"
        }, [_c('p', [_vm._v(_vm._s(scope.row.name))]), _c('p', {
          staticClass: "color9"
        }, [_vm._v(_vm._s(_vm.targetTypeObj[scope.row.targetType]))]), _c('p', {
          staticClass: "color9"
        }, [_vm._v("开始" + _vm._s(scope.row.startDate))]), _c('p', {
          staticClass: "color9"
        }, [_vm._v("结束" + _vm._s(scope.row.endDate))])])]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "竞拍规则",
      "width": "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {
          staticClass: "y3 height"
        }, [_c('p', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(scope.row.biddingAmount)))]), _c('p', {
          staticClass: "color9"
        }, [_vm._v(" 加价幅度¥" + _vm._s(_vm._f("toThousands")(scope.row.biddingStepAmount)) + " ")]), scope.row.delay ? _c('p', {
          staticClass: "color9"
        }, [_vm._v(" 延时周期" + _vm._s(scope.row.delay) + "分钟 ")]) : _vm._e(), _c('p', {
          staticClass: "color9"
        }, [_vm._v(" 保证金¥" + _vm._s(_vm._f("toThousands")(scope.row.ensureAmount)) + " ")])]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "标的物介绍",
      "width": "140px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          class: {
            red: !scope.row.hasRemark
          },
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.editRemark(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.hasRemark ? "编辑介绍" : "创建介绍") + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "竞拍报名",
      "width": "140px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.appointmentCount ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.lookSignUp(scope.row);
            }
          }
        }, [_vm._v(" 查看报名(" + _vm._s(scope.row.appointmentCount) + ") ")]) : _c('p', [_vm._v("-")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "竞拍进度",
      "width": "240px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {
          staticClass: "height"
        }, [scope.row.currentStatus == 5 ? _c('p', {
          staticClass: "color9"
        }, [_vm._v("流拍")]) : scope.row.currentStatus == 1 || scope.row.currentStatus == 3 || scope.row.currentStatus == 7 ? _c('p', [_vm._v(" - ")]) : [scope.row.biddingInfo ? _c('div', {
          staticClass: "height y3"
        }, [scope.row.biddingInfo.type == 1 ? _c('p', {
          staticClass: "lh28 red"
        }, [_vm._v(" 领先 ")]) : _vm._e(), scope.row.biddingInfo.type == 5 ? _c('p', [_vm._v(" 成交价 "), _c('span', {
          staticClass: "red"
        }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(scope.row.biddingInfo.biddingAmount)) + " ")])]) : _c('p', [_vm._v(" 出价¥" + _vm._s(_vm._f("toThousands")(scope.row.biddingInfo.biddingAmount)) + " ")]), _c('p', {
          staticClass: "color9"
        }, [_vm._v(" 竞买号：" + _vm._s(scope.row.biddingInfo.appointmentNo) + " ")]), _c('p', {
          staticClass: "color9"
        }, [_vm._v("时间：" + _vm._s(scope.row.biddingInfo.dateDesc))]), scope.row.biddingInfo.type == 5 ? _c('p', {
          staticClass: "color9"
        }, [_vm._v(" 认购单：" + _vm._s(scope.row.biddingInfo.orderNo) + " "), _c('svg-icon', {
          staticClass: "copy-icon pointer",
          attrs: {
            "icon-class": "copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(scope.row.biddingInfo.orderNo);
            }
          }
        })], 1) : _vm._e()]) : _c('p', [_vm._v("-")])]], 2);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "230px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.currentStatus == 1 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.biddingBidding(scope.row);
            }
          }
        }, [_vm._v(" 挂拍 ")]) : _vm._e(), scope.row.currentStatus == 1 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.editDialog(scope.row);
            }
          }
        }, [_vm._v(" 编辑 ")]) : _vm._e(), scope.row.currentStatus == 5 || scope.row.currentStatus == 7 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.editDialog(scope.row);
            }
          }
        }, [_vm._v(" 重新挂拍 ")]) : _vm._e(), scope.row.currentStatus == 6 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.editDialog(scope.row);
            }
          }
        }, [_vm._v(" 继续竞拍 ")]) : _vm._e(), scope.row.currentStatus == 1 || scope.row.currentStatus == 5 || scope.row.currentStatus == 7 ? _c('el-button', {
          staticClass: "red",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.removeBidding(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")]) : _vm._e(), scope.row.currentStatus == 2 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.pauseBidding(scope.row);
            }
          }
        }, [_vm._v(" 暂缓 ")]) : _vm._e(), scope.row.currentStatus == 2 || scope.row.currentStatus == 3 || scope.row.currentStatus == 6 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.withdrawBidding(scope.row);
            }
          }
        }, [_vm._v(" 撤回 ")]) : _vm._e(), scope.row.biddingCount && (scope.row.currentStatus == 2 || scope.row.currentStatus == 4 || scope.row.currentStatus == 6) ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.lookRecord(scope.row);
            }
          }
        }, [_vm._v(" 竞拍记录(" + _vm._s(scope.row.biddingCount) + ") ")]) : _vm._e()];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.query.pageNum,
      "page-sizes": _vm.pageSizes,
      "page-size": _vm.query.pageSize,
      "layout": "sizes, prev, pager, next,jumper",
      "total": _vm.total
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange,
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.query, "pageNum", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.query, "pageNum", $event);
      }
    }
  })], 1), _c('editForm', {
    attrs: {
      "visible": _vm.toggleDialogEdit,
      "option": _vm.currentItem,
      "activity": _vm.activity
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleDialogEdit = $event;
      },
      "refresh": _vm.getData
    }
  }), _c('editRemark', {
    attrs: {
      "visible": _vm.toggleDialogRemark,
      "option": _vm.currentItem
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleDialogRemark = $event;
      },
      "refresh": _vm.getData
    }
  }), _c('signUp', {
    attrs: {
      "visible": _vm.toggleDialogSignUp,
      "option": _vm.currentItem
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleDialogSignUp = $event;
      },
      "refresh": _vm.getData
    }
  }), _c('record', {
    attrs: {
      "visible": _vm.toggleDialogRecord,
      "option": _vm.currentItem
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleDialogRecord = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }