<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    class="f-dialog editRemark"
    :before-close="close"
    width="640px"
  >
    <div>
      <p class="bold fs16 color3 h60 bdb x aic pw20 mb24">
        <span class="color9">标的物：</span>
        {{ option.name }}
      </p>
      <div class="x3 aic pw20 mb16">
        <div class="color9">
          <p class="fs16">相关图片</p>
          <p>选填，支持jpg、png、gif格式，建议大小1M以内，最多可上传9张</p>
        </div>
        <f-upload
          type="4"
          ref="picUploader"
          title="批量上传图片"
          @on-success="imgUploadSuccess"
        ></f-upload>
      </div>
      <div class="img-box pw20 mb24">
        <div v-for="(item, index) in attachs" :key="index" class="img-cell">
          <img :src="item.fileUrl" class="mb8" />
          <p class="pointer x1 aic color9 fs13" @click="delImg(index)">
            <i class="el-icon-delete"></i>
            删除
          </p>
        </div>
      </div>
      <quill-editor
        ref="text"
        v-model="remark"
        class="myQuillEditor pw20 mb24"
        :options="editorOption"
      />
    </div>
    <div slot="footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { remarkBidding, getRemark } from "@/api/marketActivity";
import FUpload from "@/components/upload";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    quillEditor,
    FUpload
  },
  data() {
    return {
      remark: "",
      attachs: [],
      editorOption: {
        placeholder: "标的物详细介绍"
      }
    };
  },
  computed: {
    id() {
      return this.option.id || "";
    },
    title() {
      let title = "创建";
      if (this.option.hasRemark) {
        title = "编辑";
      }
      return title + "标的物介绍";
    }
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    }
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    init() {
      const { hasRemark } = this.option;
      if (hasRemark) {
        this.getRemark();
      } else {
        this.remark = "";
        this.attachs = [];
      }
    },
    async getRemark() {
      const res = await getRemark(this.id);
      if (res) {
        const { remark, attachs } = res;
        this.attachs = attachs;
        this.remark = remark;
      }
    },
    async submit() {
      const { id, attachs, remark } = this;
      let query = {
        remark,
        attachs
      };
      const res = await remarkBidding(id, query);
      if (res) {
        this.$showSuccess("操作成功");
        this.close();
        this.$emit("refresh");
      }
    },
    imgUploadSuccess(e) {
      if (this.attachs.length >= 9) {
        return;
      }
      let item = e[0];
      this.attachs.push({
        fileUrl: item.url,
        name: item.name
      });
    },
    delImg(index) {
      this.attachs.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.editRemark {
  ::v-deep {
    .el-dialog__body {
      padding: 0;
    }
  }
  .img-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    .img-cell {
      padding: 5px;
      border: 1px solid #f2f2f2;
      img {
        height: 90px;
        width: 100%;
      }
    }
  }
}
</style>
