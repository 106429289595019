<template>
  <div class="item-body">
    <div class="section">
      <div class="header-mol">
        <div class="info">
          <h3 class="title">全部竞拍</h3>
          <p class="summary" v-if="activity.activityType == 1">
            “选车位”类型的开盘活动，仅支持关联当前开盘活动的“车位”、“其他”标的物参加竞拍，挂拍后锁定标的物，撤回后释放；<br />
          </p>
          <p class="summary" v-else>
            “选房”类型的开盘活动，仅支持关联当前开盘活动的“房源”、“其他”标的物参加竞拍，挂拍后锁定标的物，撤回后释放；<br />
          </p>
        </div>
        <div>
          <el-button type="primary" icon="el-icon-plus" @click="editDialog()">
            创建竞拍
          </el-button>
        </div>
      </div>
      <el-table border class="body" :data="tableList">
        <el-table-column label="标的物">
          <div slot-scope="scope" class="x">
            <div class="cover mr10">
              <div
                class="img-tag"
                v-if="currentStatusObj[scope.row.currentStatus]"
                :style="
                  `background-color: ${
                    currentStatusObj[scope.row.currentStatus].color
                  }`
                "
              >
                {{ currentStatusObj[scope.row.currentStatus].name }}
              </div>
              <el-image
                class="cover"
                fit="cover"
                :src="scope.row.corverImage"
              ></el-image>
            </div>
            <div class="y3">
              <p>{{ scope.row.name }}</p>
              <p class="color9">{{ targetTypeObj[scope.row.targetType] }}</p>
              <p class="color9">开始{{ scope.row.startDate }}</p>
              <p class="color9">结束{{ scope.row.endDate }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="竞拍规则" width="200px">
          <div slot-scope="scope" class="y3 height">
            <p>¥{{ scope.row.biddingAmount | toThousands }}</p>
            <p class="color9">
              加价幅度¥{{ scope.row.biddingStepAmount | toThousands }}
            </p>
            <p class="color9" v-if="scope.row.delay">
              延时周期{{ scope.row.delay }}分钟
            </p>
            <p class="color9">
              保证金¥{{ scope.row.ensureAmount | toThousands }}
            </p>
          </div>
        </el-table-column>
        <el-table-column label="标的物介绍" width="140px">
          <template slot-scope="scope">
            <el-button
              type="text"
              :class="{ red: !scope.row.hasRemark }"
              @click="editRemark(scope.row)"
            >
              {{ scope.row.hasRemark ? "编辑介绍" : "创建介绍" }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="竞拍报名" width="140px">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.appointmentCount"
              type="text"
              @click="lookSignUp(scope.row)"
            >
              查看报名({{ scope.row.appointmentCount }})
            </el-button>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column label="竞拍进度" width="240px">
          <div slot-scope="scope" class="height">
            <p v-if="scope.row.currentStatus == 5" class="color9">流拍</p>
            <p
              v-else-if="
                scope.row.currentStatus == 1 ||
                  scope.row.currentStatus == 3 ||
                  scope.row.currentStatus == 7
              "
            >
              -
            </p>
            <template v-else>
              <div v-if="scope.row.biddingInfo" class="height y3">
                <p class="lh28 red" v-if="scope.row.biddingInfo.type == 1">
                  领先
                </p>
                <p v-if="scope.row.biddingInfo.type == 5">
                  成交价
                  <span class="red">
                    ¥{{ scope.row.biddingInfo.biddingAmount | toThousands }}
                  </span>
                </p>
                <p v-else>
                  出价¥{{ scope.row.biddingInfo.biddingAmount | toThousands }}
                </p>
                <p class="color9">
                  竞买号：{{ scope.row.biddingInfo.appointmentNo }}
                </p>
                <p class="color9">时间：{{ scope.row.biddingInfo.dateDesc }}</p>
                <p class="color9" v-if="scope.row.biddingInfo.type == 5">
                  认购单：{{ scope.row.biddingInfo.orderNo }}
                  <svg-icon
                    icon-class="copy"
                    class="copy-icon pointer"
                    @click="copyText(scope.row.biddingInfo.orderNo)"
                  ></svg-icon>
                </p>
              </div>
              <p v-else>-</p>
            </template>
          </div>
        </el-table-column>
        <el-table-column label="操作" width="230px">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.currentStatus == 1"
              type="text"
              @click="biddingBidding(scope.row)"
            >
              挂拍
            </el-button>
            <el-button
              v-if="scope.row.currentStatus == 1"
              type="text"
              @click="editDialog(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                scope.row.currentStatus == 5 || scope.row.currentStatus == 7
              "
              type="text"
              @click="editDialog(scope.row)"
            >
              重新挂拍
            </el-button>
            <el-button
              v-if="scope.row.currentStatus == 6"
              type="text"
              @click="editDialog(scope.row)"
            >
              继续竞拍
            </el-button>
            <el-button
              v-if="
                scope.row.currentStatus == 1 ||
                  scope.row.currentStatus == 5 ||
                  scope.row.currentStatus == 7
              "
              type="text"
              class="red"
              @click="removeBidding(scope.row)"
            >
              删除
            </el-button>
            <el-button
              v-if="scope.row.currentStatus == 2"
              type="text"
              @click="pauseBidding(scope.row)"
            >
              暂缓
            </el-button>
            <el-button
              v-if="
                scope.row.currentStatus == 2 ||
                  scope.row.currentStatus == 3 ||
                  scope.row.currentStatus == 6
              "
              type="text"
              @click="withdrawBidding(scope.row)"
            >
              撤回
            </el-button>
            <el-button
              v-if="
                scope.row.biddingCount &&
                  (scope.row.currentStatus == 2 ||
                    scope.row.currentStatus == 4 ||
                    scope.row.currentStatus == 6)
              "
              type="text"
              @click="lookRecord(scope.row)"
            >
              竞拍记录({{ scope.row.biddingCount }})
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="query.pageNum"
        :page-sizes="pageSizes"
        :page-size="query.pageSize"
        layout="sizes, prev, pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <editForm
      :visible.sync="toggleDialogEdit"
      :option="currentItem"
      :activity="activity"
      @refresh="getData"
    />
    <editRemark
      :visible.sync="toggleDialogRemark"
      :option="currentItem"
      @refresh="getData"
    />
    <signUp
      :visible.sync="toggleDialogSignUp"
      :option="currentItem"
      @refresh="getData"
    />
    <record :visible.sync="toggleDialogRecord" :option="currentItem" />
  </div>
</template>

<script>
import {
  getBiddingList,
  removeBidding,
  biddingBidding,
  pauseBidding,
  withdrawBidding
} from "@/api/marketActivity";

import editForm from "./components/edit";
import editRemark from "./components/editRemark";
import signUp from "./components/signUp";
import record from "./components/record";
import { copyText } from "@/utils";

export default {
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  components: {
    editForm,
    editRemark,
    signUp,
    record
  },
  data() {
    return {
      tableList: [],
      query: {
        pageNum: 1,
        pageSize: 10,
        openQuotationActivityId: ""
      },
      pageSizes: [10, 20, 30, 50],
      total: 0,
      toggleDialogEdit: false,
      toggleDialogRemark: false,
      toggleDialogSignUp: false,
      toggleDialogRecord: false,
      currentItem: {},
      currentStatusObj: {
        1: {
          name: "草稿",
          color: "#969aa4"
        },
        2: {
          name: "正在进行",
          color: "#eb3324"
        },
        3: {
          name: "即将开始",
          color: "#e9a84c"
        },
        4: {
          name: "已结束",
          color: "#377d23"
        },
        5: {
          name: "已结束",
          color: "#377d23"
        },
        6: {
          name: "暂缓",
          color: "#eb4a96"
        },
        7: {
          name: "撤回",
          color: "#949aa4"
        }
      },
      targetTypeObj: {
        1: "房源",
        2: "车位",
        3: "其它"
      }
    };
  },
  watch: {
    "activity.id"(e) {
      this.query.openQuotationActivityId = e;
      this.getData();
    }
  },
  methods: {
    copyText,
    editDialog(item) {
      this.toggleDialogEdit = true;
      this.currentItem = item ? this.$deepClone(item) : {};
    },
    editRemark(item) {
      this.toggleDialogRemark = true;
      this.currentItem = this.$deepClone(item);
    },
    lookSignUp(item) {
      this.toggleDialogSignUp = true;
      this.currentItem = this.$deepClone(item);
    },
    lookRecord(item) {
      this.toggleDialogRecord = true;
      this.currentItem = this.$deepClone(item);
    },
    async getData() {
      const res = await getBiddingList(this.query);
      if (res) {
        const { list, total } = res;
        this.tableList = list || [];
        this.total = total;
      }
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.query.pageNum = val;
      this.getData();
    },
    biddingBidding(item) {
      this.$showConfirm(
        async () => {
          const res = await biddingBidding(item.id);
          if (res) {
            this.$showSuccess("操作成功");
            this.getData();
          }
        },
        "挂拍后，该标的物将被锁定，确定要执行？",
        `“${item.name}”挂拍`
      );
    },
    pauseBidding(item) {
      this.$prompt(
        `暂缓竞拍，将保留竞拍记录，标的物依然处于锁定状态，可重新挂拍，确定要执行？`,
        `“${item.name}”暂缓`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          inputPattern: /[\S]+/,
          inputPlaceholder: "请输入理由",
          inputErrorMessage: "请输入理由！"
        }
      ).then(async ({ value }) => {
        let query = {
          reamk: value
        };
        const res = await pauseBidding(item.id, query);
        if (res) {
          this.$showSuccess("操作成功");
          this.getData();
        }
      });
    },
    withdrawBidding(item) {
      this.$prompt(
        `撤回后竞拍记录将清空，同时释放标的物，保证金自动原路退款，确定要执行？`,
        `“${item.name}”撤回`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          inputPattern: /[\S]+/,
          inputPlaceholder: "请输入理由",
          inputErrorMessage: "请输入理由！"
        }
      ).then(async ({ value }) => {
        let query = {
          reamk: value
        };
        const res = await withdrawBidding(item.id, query);
        if (res) {
          this.$showSuccess("操作成功");
          this.getData();
        }
      });
    },
    removeBidding(item) {
      this.$showConfirm(
        async () => {
          const res = await removeBidding(item.id);
          if (res) {
            this.$showSuccess("操作成功");
            this.getData();
          }
        },
        "是否确认删除该数据？",
        `“${item.name}”删除`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.item-body {
  background-color: #ffffff;
  padding: 0 20px 20px 20px;
  .section {
    .header-mol {
      padding-left: 20px;
    }
  }
  .body {
    margin-top: 30px;
    ::v-deeptd .cell {
      height: 90px;
    }
    ::v-deep {
      .el-button--text {
        padding: 0;
      }
    }
    .cover {
      min-width: 120px;
      width: 120px;
      height: 90px;
      border-radius: 2px;
      overflow: hidden;
      position: relative;
      .img-tag {
        position: absolute;
        left: 0;
        top: 0;
        width: 60px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 13px;
        color: #fff;
        z-index: 1;
      }
      .img-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 120px;
        height: 90px;
        border-radius: 1px;
        z-index: 1;
        background: rgba($color: #000000, $alpha: 0.3);
        font-size: 13px;
        color: #fff;
      }
    }
  }
}
</style>
