<template>
  <el-dialog
    :visible.sync="visible"
    title="创建退款"
    :close-on-click-modal="false"
    class="f-dialog"
    :before-close="close"
    width="500px"
    append-to-body
  >
    <div>
      <div class="pb24 bdb x3 aic">
        <div>
          <p class="fs16 bold mb8">保证金</p>
          <p class="fs13 color9">竞拍保证金</p>
        </div>
        <img style="width:30px;" src="../../../../../assets/tui.png" />
      </div>
      <div class="ph24 bdb">
        <div class="x3 aic mb8">
          <p>实收保证金</p>
          <p>¥{{ option.payAmount | toThousands }}</p>
        </div>
        <div class="x3 aic mb8">
          <p>诚意金转入</p>
          <p>¥{{ option.balanceAmount | toThousands }}</p>
        </div>
        <div class="x3 aic mb8">
          <p>微信支付</p>
          <p>¥{{ option.onlineAmount | toThousands }}</p>
        </div>
        <div class="x3 aic mb8">
          <p>线下支付</p>
          <p>¥{{ option.offlineAmount | toThousands }}</p>
        </div>
      </div>
      <div class="ph24 bdb">
        <div class="x3 aic mb8">
          <p>可退款金额</p>
          <p class="fs20 red">¥{{ allRefundAmoun | toThousands }}</p>
        </div>
        <p class="mb8">
          其中，“微信支付”退款 ¥{{
            option.onlineRefundAmount | toThousands
          }}；“线下支付”退款 ¥{{
            option.offlineRefundAmount | toThousands
          }}；诚意金”退还 ¥{{ option.balanceRefundAmount | toThousands }}
        </p>
        <p class="color9">
          “微信支付”部分发起退款后，将原路退回到对应微信账户，实时到账，不支持撤回；
        </p>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">
        确认退款
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { manageRefund } from "@/api/marketActivity";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    allRefundAmoun() {
      let all = 0;
      const {
        onlineRefundAmount,
        offlineRefundAmount,
        balanceRefundAmount
      } = this.option;
      all = onlineRefundAmount + offlineRefundAmount + balanceRefundAmount;
      return all;
    }
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    async submit() {
      let id = this.option.id;
      const res = await manageRefund(id);
      if (res) {
        this.$showSuccess("操作成功");
        this.$emit("refresh");
      }
    }
  }
};
</script>
